import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyDto } from '../models/CurrencyDto';

@Pipe({
  name: 'priceFormat'
})
export class PriceFormatPipe implements PipeTransform {
  transform(value?: string | number, currency: CurrencyDto = CurrencyDto.default()): string {
    if (value == null) {
      return '';
    }

    const numberValue = Number(value || 0);

    // Check if the number is an integer or has decimal places
    const isInteger = numberValue % 1 === 0;

    // Format the number
    const formattedValue = new Intl.NumberFormat(currency.locale, {
      style: 'currency',
      currency: currency.code,
      // Use minimumFractionDigits and maximumFractionDigits to manage decimals
      minimumFractionDigits: isInteger ? 0 : 2,
      maximumFractionDigits: 2, // This ensures only two decimal places if needed
      currencyDisplay: 'symbol'
    }).format(numberValue);

    return formattedValue;
  }
}
