import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";
import { AuctionUiDto } from "../../models/auction/AuctionUiDto";
import { DraftSubStatus } from "../../enums/TenderDraftSubStatus";

export const AuctionActions = createActionGroup({
  source: 'AuctionActions',
  events: {
    'Set Current Sourcing Event Ui Dto': props<{ sourcingEventUiDto: SourcingEventUiDto }>(),
    'Get Sourcing Event Ui Dto': emptyProps(),

    'Set Current Auction Ui Dto': props<{ auctionUiDto: AuctionUiDto }>(),
    'Get Current Auction Ui Dto': emptyProps(),
    'Clear Current Auction Ui Dto': emptyProps(),

    'Set Auction Draft Status': props<{draftSubStatus: DraftSubStatus}>(),
  }
});