import { Pipe, PipeTransform } from '@angular/core';
import { CountryCodeDto } from '../models/CountryCodeDto';

@Pipe({
  name: 'countryCode'
})
export class CountryCodePipe implements PipeTransform {

  transform(countryCodeDto?: CountryCodeDto): string {
    if (countryCodeDto) {
      return countryCodeDto.countryCode!.replace('(', '').replace(')', '');
    }
    return '+91';
  }

}
