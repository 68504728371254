import { Injectable } from '@angular/core';
import { OnboardingCountryDto } from './models/OnboardingCountryDto';
import { catchError, firstValueFrom, Observable, tap } from 'rxjs';
import { ApplicationConstants } from './utils/ApplicationConstants';
import { TimeZoneDto } from './models/TimeZoneDto';
import { ServerAPIResponseDto } from './models/ServerAPIResponseDto';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from './services/error.service';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {
  timezones: TimeZoneDto[] = [];

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
  ) { }

  get getOnboardingCountries() { return onboardingCountries; };
  get getTimezones() { return this.timezones; };

  async initMasterData() {
    await this.loadTimezoneDataFromServerSync();
  }

  /**
  * Fetch the master data from server
  * This method must be call ones
  */
  async loadTimezoneDataFromServerSync() {
    try {
      let apiResponseDto = await firstValueFrom(this.getTimezonesDataFromServer());
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let timezones = apiResponseDto.data as TimeZoneDto[];
        this.timezones = timezones || [];
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Api Calls
  
  /**
  * Get the timezones data from server.
  * This methods is called on application startup. Its a one time call.
  * @returns
  */
  getTimezonesDataFromServer(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('assets/timezones.json').pipe(
      tap(_ => console.log("Get timezones")),
      catchError(this.errorService.handleError<any>("Error while getting timezones")));
  }
}

const onboardingCountries: OnboardingCountryDto[] = [
  {
    name: "Australia",
    taxOne: 'Australian Business Number (ABN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Brazil",
    taxOne: 'Cadastro Nacional da Pessoa Jurídica (CNPJ)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Argentina",
    taxOne: 'Clave Única de Identificación Tributaria (CUIT)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Bahrain",
    taxOne: 'Commercial Registration Number (CR)',
    taxOneLengthMin: 3,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Canada",
    taxOne: 'Business Number (BN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: 'GST/HST Number',
    taxTwoLengthMin: 15,
    taxTwoLengthMax: 15,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "France",
    taxOne: 'Numéro SIRET (Système d\'Identification du Répertoire des Etablissements)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Germany",
    taxOne: 'Umsatzsteuer-Identifikationsnummer (USt-IdNr)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Ghana",
    taxOne: 'Taxpayer Identification Number (TIN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "India",
    taxOne: 'Permanent Account Number (PAN)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: 'GSTIN',
    taxTwoLengthMin: 15,
    taxTwoLengthMax: 15,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "Indonesia",
    taxOne: 'Nomor Pokok Wajib Pajak (NPWP)',
    taxOneLengthMin: 15,
    taxOneLengthMax: 15,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Ireland",
    taxOne: 'VAT Registration Number',
    taxOneLengthMin: 8,
    taxOneLengthMax: 9,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Kenya",
    taxOne: 'Personal Identification Number (PIN)',
    taxOneLengthMin: 11,
    taxOneLengthMax: 11,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Kuwait",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 6,
    taxOneLengthMax: 8,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Malaysia",
    taxOne: 'Business Registration Number (BRN)',
    taxOneLengthMin: 8,
    taxOneLengthMax: 12,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Netherlands",
    taxOne: 'BTW-nummer (VAT Number)',
    taxOneLengthMin: 14,
    taxOneLengthMax: 14,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Nigeria",
    taxOne: 'Taxpayer Identification Number (TIN)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Oman",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 3,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Philippines",
    taxOne: 'Tax Identification Number (TIN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Qatar",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Saudi Arabia",
    taxOne: 'Commercial Registration Number',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: 'VAT Registration Number',
    taxTwoLengthMin: 10,
    taxTwoLengthMax: 10,
    taxTwoType: 'Numeric'
  },
  {
    name: "Singapore",
    taxOne: 'Unique Entity Number (UEN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: 'GST Registration Number (GRN)',
    taxTwoLengthMin: 10,
    taxTwoLengthMax: 10,
    taxTwoType: 'Alphanumeric'
  },
  {
    name: "South Africa",
    taxOne: 'Value-Added Tax (VAT) Number',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Spain",
    taxOne: 'Número de Identificación Fiscal (NIF)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 11,
    taxOneType: 'Alphanumeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "Thailand",
    taxOne: 'Tax Identification Number (TIN)',
    taxOneLengthMin: 13,
    taxOneLengthMax: 13,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United Arab Emirates",
    taxOne: 'Tax Registration Number (TRN)',
    taxOneLengthMin: 15,
    taxOneLengthMax: 15,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United Kingdom",
    taxOne: 'Company Unique Taxpayer Reference (UTR)',
    taxOneLengthMin: 10,
    taxOneLengthMax: 10,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  },
  {
    name: "United States",
    taxOne: 'Employer Identification Number (EIN)',
    taxOneLengthMin: 9,
    taxOneLengthMax: 9,
    taxOneType: 'Numeric',
    taxTwo: undefined,
    taxTwoLengthMin: undefined,
    taxTwoLengthMax: undefined,
    taxTwoType: undefined
  }
];
