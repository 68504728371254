import { createFeature, createReducer, on } from "@ngrx/store";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";
import { AuctionUiDto } from "../../models/auction/AuctionUiDto";
import { AuctionActions } from "./auction.actions";

interface State {
  sourcingEventUiDto: SourcingEventUiDto | null;
  auctionUiDto: AuctionUiDto | null;
}

const initialState: State = {
  sourcingEventUiDto: null,
  auctionUiDto: null,
};

export const auctionFeature = createFeature({
  name: 'AuctionFeature',
  reducer: createReducer(
    initialState,
    on(AuctionActions.setCurrentSourcingEventUiDto, (state, { sourcingEventUiDto }) => {
      localStorage.setItem('currentEvent', JSON.stringify(sourcingEventUiDto));
      return {
        ...state,
        sourcingEventUiDto
      }
    }),
    on(AuctionActions.getSourcingEventUiDto, (state) => ({
      ...state,
    })),

    // Current Auction Wrapper Ui Dto
    on(AuctionActions.setCurrentAuctionUiDto, (state, { auctionUiDto }) => ({
      ...state,
      auctionUiDto
    })),
    on(AuctionActions.getCurrentAuctionUiDto, (state) => ({
      ...state,
    })),
    on(AuctionActions.clearCurrentAuctionUiDto, (state) => ({
      ...state,
      auctionUiDto: null,
    })),
    on(AuctionActions.setAuctionDraftStatus, (state, { draftSubStatus }) => ({
      ...state,
      sourcingEventUiDto: {
        ...state.sourcingEventUiDto,
        stages: {
          ...state.sourcingEventUiDto?.stages, // Preserve existing stages
          [draftSubStatus]: true // Dynamically set the status to true
        }
      }
    }))
  ),
});

export const {
  name,
  reducer,
  selectAuctionFeatureState,
  selectSourcingEventUiDto,
  selectAuctionUiDto,
} = auctionFeature