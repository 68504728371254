import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { NavigationDto } from "../../models/NavigationDto";

export const NavigationActions = createActionGroup({
  source: 'Navigation',
  events: {
    'Set Auth View': props<{ authView: string }>(),
    'Get Auth View': emptyProps(),

    'Set Current Path': props<{ navigation: NavigationDto }>(),
    'Get Current Path': emptyProps(),
    'Clear Current Path': emptyProps(),

    'Set Current Tab': props<{ currentTab: string }>(),
    'Get Current Tab': emptyProps(),
  }
})
