import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { OrganizationWrapperUiDto } from '../../models/OrganizationWrapperUiDto';
import { UserUiDto } from '../../models/UserUiDto';
import { AuthenticationService } from '../../services/authentication.service';
import { selectUserUiDto } from '../../state-management/session/session.features';
import { firstValueFrom, Subscription } from 'rxjs';
import { selectOrganizationUiDto } from '../../state-management/onboarding/onboarding.features';
import { selectBreadcrumbs, selectCurrentNavigation } from '../../state-management/navigation/navigation.features';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  organizationUiDto?: OrganizationWrapperUiDto;
  userUiDto?: UserUiDto;

  headerName: 'DEFAULT' | 'ONBOARDING' = 'DEFAULT';
  onboardingPages = ['basicDetails', 'dscDetails']

  breadcrumbs: string[] = [];

  organizationSubscription$?: Subscription;
  userSubscription$?: Subscription;
  routerSubscription$?: Subscription;
  breadcrumbSubscription$?: Subscription;

  menuItems = [
    { title: 'Edit', escape: false },
  ]

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.organizationSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe(organizationUiDto => {
      if (organizationUiDto) {
        this.organizationUiDto = organizationUiDto;
      } else {
        this.organizationUiDto = undefined;
      }
    })

    this.userSubscription$ = this.store.pipe(select(selectUserUiDto)).subscribe(userUiDto => {
      if (userUiDto) {
        this.userUiDto = userUiDto;
      } else {
        this.userUiDto = undefined;
      }
    })

    this.routerSubscription$ = this.router.events.subscribe(() => {
      this.checkHeaderVisibility();
    });

    this.breadcrumbSubscription$ = this.store.pipe(select(selectBreadcrumbs)).subscribe(breadcrumbs => {
      if (breadcrumbs) {
        this.breadcrumbs = breadcrumbs;
      } else {
        this.breadcrumbs = [];
      }
    })
  }

  checkHeaderVisibility() {
    // Get the current active route path
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    // Now, route should be the last active route
    let currentPath = route.snapshot.routeConfig?.path || '';
    // console.log('Current Path:', currentPath);

    // Set the visibility of the header based on the path
    if (this.onboardingPages.includes(currentPath)) {
      this.headerName = 'ONBOARDING';
    } else {
      this.headerName = 'DEFAULT';
    }
  }

  logout() {
    this.authService.logout();
  }

  navigateToForgotPassword() {
    this.router.navigate(["/resetPassword"], { skipLocationChange: true })
  }

  navigateToProfile(){
    this.router.navigate(["/profile"], {skipLocationChange: true})
  }

  async navigateBreadcrumbPage(index: number) {
    let navigation = await firstValueFrom(this.store.pipe(select(selectCurrentNavigation)));
    const pathParts = navigation?.path.split('/').filter(item => item !== '') || [];

    let path = pathParts.slice(0, index + 1).join('/');
    this.router.navigate([path], { skipLocationChange: true, state: navigation?.data || {} });
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe();
    }
    if (this.userSubscription$) {
      this.userSubscription$.unsubscribe();
    }
    if (this.breadcrumbSubscription$) {
      this.breadcrumbSubscription$.unsubscribe();
    }
  }

}
