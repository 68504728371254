import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationUtils } from '../utils/ApplicationUtils';
import { TimeZoneDto } from '../models/TimeZoneDto';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {
  transform(value?: string, params?: 'short' | 'long' | 'shortDateTime' | 'longDateTime'): string {
    if (!value) {
      return '';
    }

    let timeZone = TimeZoneDto.default();
    let dateObj = ApplicationUtils.convertDateFormat(value)!;

    const date = dateObj.getDate();
    const suffix = this.getOrdinalSuffix(date);
    const monthLong = dateObj.toLocaleString('en-US', { month: 'long' });
    const monthShort = dateObj.toLocaleString('en-US', { month: 'short' });
    const year = dateObj.getFullYear();

    const hours24 = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const hours12 = hours24 % 12 || 12; // Convert to 12-hour format
    const amPm = hours24 >= 12 ? 'PM' : 'AM'; // Determine AM/PM
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Ensure two digits for minutes

    if (params) {
      if (params == 'short') {
        return `${date}${suffix} ${monthShort} ${timeZone.nameCode}`;
      } else if (params == 'long') {
        return `${date}${suffix} ${monthLong}, ${year} ${timeZone.nameCode}`;
      } else if (params == 'shortDateTime') {
        return `${date}${suffix} ${monthShort} ${hours12}:${formattedMinutes} ${amPm} ${timeZone.nameCode}`;
      } else if (params == 'longDateTime') {
        return `${date}${suffix} ${monthLong}, ${year} ${hours12}:${formattedMinutes} ${amPm} ${timeZone.nameCode}`;
      }
    }
    return `${date}${suffix} ${monthLong}, ${year} ${timeZone.nameCode}`;
  }

  private getOrdinalSuffix(day: number): string {
    if (day >= 11 && day <= 13) {
      return 'th';
    }
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
