import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { OrganizationWrapperUiDto } from "../../models/OrganizationWrapperUiDto";
import { CompanyLightUiDto, PlantLightUiDto } from "../../models/CompanyLightWrapperUiDto";

export const OnboardingActions = createActionGroup({
  source: 'Onboarding',
  events: {
    'Update Organization Ui Dto': props<{ organizationUiDto: OrganizationWrapperUiDto }>(),
    'Get Org Organogram': emptyProps(),
    'Clear Org Organogram': emptyProps(),

    'Set Short Companies Data': props<{ companyShortUiDtos: CompanyLightUiDto[] }>(),
    'Update Short Companies Data': props<{ companyShortUiDto: CompanyLightUiDto }>(),
    'Get Short Companies Data': emptyProps(),

    'Set Short Plants Data': props<{ plantShortUiDtos: PlantLightUiDto[] }>(),
    'Update Short Plants Data': props<{ plantShortUiDto: PlantLightUiDto }>(),
    'Get Short Plants Data': emptyProps(),
  }
})
