import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.sass']
})
export class ProgressCircleComponent implements OnChanges {
  @Input() value: number = 0; // Current value (input from parent)
  @Input() maxValue: number = 100; // Maximum value (input from parent)

  percentage: number = 0; // Calculated percentage
  circumference: number = 0; // Circle circumference
  strokeDashoffset: number = 0; // Offset for the circle stroke
  strokeColor: string = 'text-gray-300'; // Circle progress color (dynamic)

  ngOnChanges(changes: SimpleChanges): void {
    const radius = 15; // Circle radius
    this.circumference = 2 * Math.PI * radius; // Calculate circumference

    this.updateProgress();
  }

  private updateProgress(): void {
    // Calculate percentage
    this.percentage = Math.min((this.value / this.maxValue) * 100);
    let percentageForStroke = Math.min((this.value / this.maxValue) * 100, 100); // Cap percentage at 100

    // Calculate stroke offset
    this.strokeDashoffset = this.circumference - (this.circumference * percentageForStroke) / 100;
    
    // Determine stroke color based on percentage
    if (this.percentage === 0) {
      this.strokeColor = 'text-gray-300'; // No progress
    } else if (this.percentage > 0 && this.percentage <= 100) {
      this.strokeColor = 'text-green-500'; // Progress between 1% and 100% is green
    } else {
      this.strokeColor = 'text-red-500'; // Progress at 100% is red
    }
  }
}
