import * as moment from 'moment-timezone';
import { UserUiDto } from "../models/UserUiDto";
import { TimeZoneDto } from '../models/TimeZoneDto';

export class ApplicationUtils {
  static readonly momentFormat = "DD/MM/YYYY HH:mm";

  static clone(obj: any) {
    if (obj == null || typeof (obj) != 'object')
      return obj;

    var temp = new obj.constructor();
    for (var key in obj)
      temp[key] = this.clone(obj[key]);

    return temp;
  }

  static deepClone(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    // Handle arrays
    if (Array.isArray(obj)) {
      return obj.map(item => ApplicationUtils.deepClone(item));
    }

    // Handle objects
    const clonedObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = ApplicationUtils.deepClone(obj[key]);
      }
    }
    return clonedObj;
  }

  //  Dates
  static getCurrentDate(timeZoneDto?: TimeZoneDto) {
    if (timeZoneDto) {
      return moment.tz(TimeZoneDto.default().locale!).toDate();
    }
    return moment().toDate();
  }

  static convertedDate(date: string, time: string): Date | undefined {
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      newDate.setHours(Number.parseInt(time?.split(':')[0]), Number.parseInt(time?.split(':')[1]));
      return newDate;
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return undefined;
  }

  static convertDateFormat(dateInIST: string, timeZone?: TimeZoneDto): Date | null {
    if (!dateInIST) {
      return null;
    }

    let istMoment = moment(dateInIST, this.momentFormat);

    if (!istMoment.isValid()) {
      console.error('Invalid date format. Expected format: DD/MM/YYYY HH:mm');
      return null; // Return null if the format is incorrect
    }

    if (timeZone) {
      istMoment = istMoment.tz(timeZone.locale!);
    }
   
    return istMoment.toDate();
  }

  static getRemainingTime(dateInString: string, timezone: TimeZoneDto) {
    const targetMoment = moment.tz(dateInString, this.momentFormat, timezone.locale!);

    if (!targetMoment.isValid()) {
      throw new Error('Invalid date format or timezone!');
    }

    const currentMoment = moment.tz(timezone.locale!);
    const remainingTimeMillis = targetMoment.diff(currentMoment);

    return remainingTimeMillis;
  }

  static convertedOnlyDate(date: string): Date | undefined {
    try {
      let d = date.split("/");
      let newDate = new Date(d[2] + '/' + d[1] + '/' + d[0]);
      return newDate;
    } catch (e) {
      let result = (e as Error).message;
      console.log(result);
    }
    return undefined;
  }

  // Text
  static truncateString(input: string, maxLength: number) {
    let formattedInput = input.replace(/<\/?[^>]+(>|$)/g, "");
    formattedInput = formattedInput.replace(/&#[0-9]+;/g, "");
    formattedInput = formattedInput.replace(/&nbsp;/g, ' ');
    formattedInput = formattedInput.replace(/&amp;/g, '&');

    if (formattedInput.length > maxLength) {
      return formattedInput.substring(0, maxLength) + '...';
    }
    return formattedInput;
  }

  static removeSpecialCharacters(inputString: string): string {
    const regex = /[^a-zA-Z0-9_ -]/g;
    const cleanedString = inputString.replace(regex, '');
    return cleanedString;
  }

  static formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static base64toFile(base64: any, filename: string, mimeType: string) {
    return (fetch(base64)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  static isValidColor(strColor: string) {
    var s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  }

  /**
   *  [type]
   *  Zip Code      => postal_code
   *  State         => administrative_area_level_1
   *  City          => administrative_area_level_2, administrative_area_level_3
   *  Locality      => locality
   *  Country       => country
   * */
  static getAddressByType(address: google.maps.places.PlaceResult, type: string): string {
    let toReturn = '';
    for (var i = 0; i < address.address_components!.length; i++) {
      for (var j = 0; j < address.address_components![i].types.length; j++) {
        if (address.address_components![i].types[j] == type) {
          toReturn = address.address_components![i].long_name;
        }
      }
    }
    return toReturn;
  }

  static getAddressShortNameByType(address: google.maps.places.PlaceResult, type: string): string {
    let toReturn = '';
    for (var i = 0; i < address.address_components!.length; i++) {
      for (var j = 0; j < address.address_components![i].types.length; j++) {
        if (address.address_components![i].types[j] == type) {
          toReturn = address.address_components![i].short_name;
        }
      }
    }
    return toReturn;
  }

  static checkIfRoleIsAssigned(userPrivileges: string[], privilege: string) {
    return userPrivileges.includes(privilege);
  }

  static isSuperAdmin(currentUser: UserUiDto): boolean {
    if (currentUser) {
      return currentUser.appAdminCode == 1;
    }
    return false;
  }

 static preventScientificNotation(event: KeyboardEvent) {
  const target = event.target as HTMLSelectElement;

    // Check if the pressed key is 'e' or 'E'
    if (event.key === 'e' || event.key === 'E' || event.key=='-') {
        event.preventDefault(); // Prevent the default action
    }else if(target && target.value !==''){
      const inputValue = target.value;
      const regex = /^\d+(\.\d{1,1})?$/; // Regular expression to allow only two decimal places
  
      if (!regex.test(inputValue)) {       
        event.preventDefault(); // Prevent form submission
      }
      else if (parseFloat(target.value) > 999999999999.9999) {
        event.preventDefault(); // Prevent the default action
      }
      
    }
}
}