export class TimeZoneDto {
  fullName?: string;
  locale?: string;
  nameCode?: string;
  utcCode?: string;
  code?: string;

  static default(): TimeZoneDto {
    let timeZone = new TimeZoneDto()
    timeZone.nameCode = 'IST';
    timeZone.utcCode = 'UTC+05:30';
    timeZone.fullName = 'UTC+05:30 - Indian Standard Time (IST)';
    timeZone.locale = 'Asia/Kolkata';
    timeZone.code = '+05.30';
    return timeZone;
  }
}