import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';
import { OrganizationThemeEnum } from '../enums/OrganizationThemeEnum';
import { ThemeStructure, ThemeStructureForDto, ThemeStructureForSize, ThemeStructureValidation } from '../models/ThemeStructure';
import { ColorValidators } from '../validators/color.validators';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  themeStructure?: ThemeStructure;

  constructor(
    private http: HttpClient
  ) { }

  async loadDefaultThemeStructure() {
    try {
      this.themeStructure = await firstValueFrom(this.http.get(`/assets/theme-1.json`));
    } catch (error) {
      console.error(error);
    }
  }

  getThemeStructure(organizationUiDto: OrganizationWrapperUiDto) {
    let themeFile = 'theme-1.json';

    if (organizationUiDto.theme == OrganizationThemeEnum.MATERIAL_AND_SERVICES) {
      themeFile = 'theme-1.json';
    }

    this.http.get(`/assets/${themeFile}`).subscribe({
      next: (data) => {
        this.themeStructure = data;
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  createFormConfig(sizes?: ThemeStructureForSize[]): { [key: string]: any } {
    const config: { [key: string]: any } = {};

    sizes?.forEach(size => {
      if (size.name) {
        config[size.name] = {
          label: size.labelName || '',
          validations: this.createValidationMessages(size.validations),
        };
      }
    });

    return config;
  }

  createValidationMessages(validations?: ThemeStructureValidation[]): { [key: string]: string } {
    const validationMessages: { [key: string]: string } = {};

    validations?.forEach(validation => {
      if (validation.type) {
        validationMessages[validation.type] = validation.errorMessage || 'Invalid field';
      }
    });

    return validationMessages;
  }

  createSuccessMessage(themeStructureForDto: ThemeStructureForDto): { [key: string]: string } {
    const successMessages: { [key: string]: string } = {};

    if (themeStructureForDto.create && themeStructureForDto.create.successMessage) {
      successMessages['create'] = themeStructureForDto.create.successMessage || '';
    }

    if (themeStructureForDto.update && themeStructureForDto.update.successMessage) {
      successMessages['update'] = themeStructureForDto.update.successMessage || '';
    }

    if (themeStructureForDto.delete && themeStructureForDto.delete.successMessage) {
      successMessages['delete'] = themeStructureForDto.delete.successMessage || '';
    }

    return successMessages;
  }

  applyValidations(formGroup: FormGroup, sizes?: ThemeStructureForSize[]): void {
    sizes?.forEach(size => {
      if (size.name) {
        const control = formGroup.get(size.name);
        if (control) {
          const validators = this.mapValidations(size.validations);
          control.setValidators(validators);
          control.updateValueAndValidity();
        }
      }
    });
  }

  private mapValidations(validations?: ThemeStructureValidation[]): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    validations?.forEach(validation => {
      if (validation.type === 'required') {
        validators.push(Validators.required);
      }
      if (validation.type === 'pattern' && validation.pattern) {
        validators.push(Validators.pattern(validation.pattern));
      }
      if (validation.type === 'minLength' && validation.value) {
        validators.push(Validators.minLength(validation.value || 0));
      }
      if (validation.type === 'maxLength' && validation.value) {
        validators.push(Validators.maxLength(validation.value || 0));
      }
      if (validation.type === 'color') {
        validators.push(ColorValidators());
      }
    });

    return validators;
  }
}
