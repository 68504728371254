import { createFeature, createReducer, on } from "@ngrx/store";
import { NavigationActions } from "./navigation.actions";
import { NavigationDto } from "../../models/NavigationDto";

interface State {
  authView: string;
  currentNavigation: NavigationDto | null;
  breadcrumbs: string[];
  currentTab: string | null;
}

const initialState: State = {
  authView: 'LOGIN',
  currentNavigation: null,
  breadcrumbs: [],
  currentTab: null
};

export const navigationFeature = createFeature({
  name: 'navigation',
  reducer: createReducer(
    initialState,
    on(NavigationActions.setAuthView, (state, { authView }) => ({
      ...state,
      authView: authView
    })),
    on(NavigationActions.getAuthView, (state) => ({
      ...state
    })),
    on(NavigationActions.setCurrentPath, (state, { navigation }) => {
      let breadcrumbs = [];
      let currentTab = null;

      const segments = navigation.path.split('/').filter(item => item !== '');

      breadcrumbs = segments.map((crumb) => crumb === 'Admin' ? 'Dashboard' : crumb);

      if (navigation.data['title']) {
        const uuidIndex = breadcrumbs.findIndex((seg) => seg.match(/^[0-9a-fA-F\-]{36}$/)); // Locate the UUID

        if (uuidIndex !== -1) {
          breadcrumbs[uuidIndex] = navigation.data['title']; // Replace UUID with title
        }
      }

      const isEvaluationPath = navigation.path.includes('evaluation');

      // Save current navigation to localStorage
      if (navigation.path != '/' && !isEvaluationPath) {
        localStorage.setItem('currentNavigation', JSON.stringify(navigation));
      }

      return {
        ...state,
        currentNavigation: navigation,
        breadcrumbs: breadcrumbs,
        currentTab: currentTab
      };
    }),
    on(NavigationActions.getCurrentPath, (state) => ({
      ...state
    })),
    on(NavigationActions.clearCurrentPath, (state) => {
      localStorage.removeItem('currentNavigation');
      return {
        ...state,
        currentNavigation: null,
        breadcrumbs: []
      }
    }),

    on(NavigationActions.setCurrentTab, (state, { currentTab }) => ({
      ...state,
      currentTab: currentTab
    })),
    on(NavigationActions.getCurrentTab, (state) => ({
      ...state
    })),
  )
});

export const {
  name,
  reducer,
  selectNavigationState,
  selectAuthView,
  selectCurrentNavigation,
  selectBreadcrumbs,
  selectCurrentTab
} = navigationFeature