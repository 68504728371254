<p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="countryName" [filter]="true"
  filterBy="countryName" [showClear]="false" (onChange)="handleCountySelection($event)" styleClass="px-2" class=" w-24 block "
  appendTo="body" [resetFilterOnHide]="true" [tabindex]="1" [disabled]="disabled">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
      <img src="/assets/images/flag_placeholder.png" [class]="'fi fi-' + selectedCountry.countryFlag" class="absolute top-2"
        style="width: 18px , " />
      <div class=" text-xs top-1.5 absolute pl-5">{{ selectedCountry.countryCode }}</div>
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="flex align-items-center gap-2">
      <img src="/assets/images/flag_placeholder.png" [class]="'fi fi-' + country.countryFlag"  />
      <div>{{ country.countryName }}</div>
      <div>{{ country.countryCode }}</div>
    </div>
  </ng-template>
</p-dropdown>