import { createFeature, createReducer, on } from "@ngrx/store";
import { OnboardingActions } from "./onboarding.actions";
import { CompanyLightUiDto, PlantLightUiDto } from "../../models/CompanyLightWrapperUiDto";
import { OrganizationWrapperUiDto } from "../../models/OrganizationWrapperUiDto";

interface State {
  organizationUiDto: OrganizationWrapperUiDto | null;
  companyCode: string | null;
  plantCode: string | null;
  companyShortUiDtos: CompanyLightUiDto[],
  plantShortUiDtos: PlantLightUiDto[],
}

const initialState: State = {
  organizationUiDto: null,
  companyCode: null,
  plantCode: null,
  companyShortUiDtos: [],
  plantShortUiDtos: []
};

export const onboardingFeature = createFeature({
  name: 'onboarding',
  reducer: createReducer(
    initialState,
    // Update Organization
    on(OnboardingActions.updateOrganizationUiDto, (state, { organizationUiDto }) => ({
      ...state,
      organizationUiDto
    })),

    // Short Data
    on(OnboardingActions.setShortCompaniesData, (state, { companyShortUiDtos }) => ({
      ...state,
      companyShortUiDtos
    })),
    on(OnboardingActions.updateShortCompaniesData, (state, { companyShortUiDto }) => {
      const existingIndex = (state.companyShortUiDtos || []).findIndex(
        dto => dto.companyCode === companyShortUiDto.companyCode
      );
    
      const updatedList = [...(state.companyShortUiDtos || [])];
    
      if (existingIndex > -1) {
        // Update the existing company
        updatedList[existingIndex] = companyShortUiDto;
      } else {
        // Add the new company
        updatedList.push(companyShortUiDto);
      }
    
      return {
        ...state,
        companyShortUiDtos: updatedList
      };
    }),
    on(OnboardingActions.getShortCompaniesData, (state) => ({
      ...state,
    })),

    on(OnboardingActions.setShortPlantsData, (state, { plantShortUiDtos }) => ({
      ...state,
      plantShortUiDtos
    })),
    on(OnboardingActions.updateShortPlantsData, (state, { plantShortUiDto }) => {
      const existingIndex = (state.plantShortUiDtos || []).findIndex(
        dto =>
          dto.companyCode === plantShortUiDto.companyCode &&
          dto.plantCode === plantShortUiDto.plantCode
      );
    
      const updatedList = [...(state.plantShortUiDtos || [])];
    
      if (existingIndex > -1) {
        // Update the existing plant
        updatedList[existingIndex] = plantShortUiDto;
      } else {
        // Add the new plant
        updatedList.push(plantShortUiDto);
      }
    
      return {
        ...state,
        plantShortUiDtos: updatedList
      };
    }),    
    on(OnboardingActions.getShortPlantsData, (state) => ({
      ...state,
    })),
  ),
});

export const {
  name,
  reducer,
  selectOnboardingState,
  selectOrganizationUiDto,
  selectCompanyShortUiDtos,
  selectPlantShortUiDtos
} = onboardingFeature