import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {

  transform(value?: string): string {
    if (!value) return value || '';

    // Replace underscores, dashes, and split CamelCase into separate words
    let formattedValue = value
      .replace(/([a-z])([A-Z])/g, '$1 $2')  // CamelCase -> Camel Case
      .replace(/[_-]/g, ' ')                // SNAKE_CASE or kebab-case -> Snake Case or Kebab Case
      .toLowerCase();                       // Convert everything to lowercase

    // Convert the first letter of each word to uppercase
    return formattedValue
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
