import { DraftSubStatus } from "../enums/TenderDraftSubStatus";

export class ApplicationConstants {
  static SUCCESS_CODE = "200";

  static USER_REGISTRATION_PROGRESS = "USER_REGISTRATION_PROGRESS";
  static BIDDER_REGISTRATION_WORKFLOW = "BIDDER_REGISTRATION_WORKFLOW";

  static tenderCreationWorkflows = [
    { label: "Configurations", value: DraftSubStatus.CONFIGURATION, complete: false },
    { label: "Address", value: DraftSubStatus.TENDER_ADDRESS, complete: false },
    { label: "Fees", value: DraftSubStatus.TENDER_FEE, complete: false },
    { label: "Critical dates", value: DraftSubStatus.TENDER_CRITICAL_DATE, complete: false },
    { label: "Documents", value: DraftSubStatus.TENDER_DOCUMENT, complete: false },
    { label: "Approvals", value: DraftSubStatus.TENDER_APPROVALS, complete: false },
    { label: "Technical Questions", value: DraftSubStatus.TENDER_TECHNICAL, complete: false },
    { label: "Financial Questions", value: DraftSubStatus.TENDER_FINANCIAL, complete: false }
  ]

  static auctionCreationWorkflows = [
    { label: "Configurations", value: DraftSubStatus.CONFIGURATION, complete: false },
    { label: "Address", value: DraftSubStatus.TENDER_ADDRESS, complete: false },
    { label: "Critical dates", value: DraftSubStatus.TENDER_CRITICAL_DATE, complete: false },
    { label: "Documents", value: DraftSubStatus.TENDER_DOCUMENT, complete: false },
    { label: "Approvals", value: DraftSubStatus.TENDER_APPROVALS, complete: false },
    { label: "Lot", value: DraftSubStatus.LOT, complete: false },
  ]

}