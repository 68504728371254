import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { TenderUiDto } from "../../models/tenders/TenderUiDto";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";
import { DraftSubStatus } from "../../enums/TenderDraftSubStatus";

export const TenderManagementActions = createActionGroup({
  source: 'TenderManagement',
  events: {
    'Set Current Sourcing Event Ui Dto': props<{sourcingEventUiDto: SourcingEventUiDto }>(),
    'Get Sourcing Event Ui Dto': emptyProps(),

    'Set Current Tender Ui Dto': props<{ tenderUiDto: TenderUiDto }>(),
    'Get Current Tender Ui Dto': emptyProps(),
    'Clear Current Tender Ui Dto': emptyProps(),

    'Get All Admin Users': emptyProps(),

    'Set Tender Draft Status': props<{draftSubStatus: DraftSubStatus}>(),
  }
})