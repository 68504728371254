import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrimengModule } from './primeng.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LucideAngularModule } from 'lucide-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryCodeSelectComponent } from './components/country-code-select/country-code-select.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { PlacesAutocompleteComponent } from './components/places-autocomplete/places-autocomplete.component';
import { OrdinalDatePipe } from './pipes/ordinal-date.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { BaseUrlPipe } from './pipes/base-url.pipe';
import { PriceFormatPipe } from './pipes/price-format.pipe';
import { TextAvatarPipe } from './pipes/text-avatar.pipe';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { CountryCodePipe } from './pipes/country-code.pipe';
import { NgxEchartsModule } from 'ngx-echarts';
import { RoleCheckPipe } from './pipes/role-check.pipe';
import { RemainingTimePipe } from './pipes/remaining-time.pipe';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GalleriaModule } from 'primeng/galleria';

const PIPES = [
  OrdinalDatePipe,
  SafePipe,
  BaseUrlPipe,
  PriceFormatPipe,
  TextAvatarPipe,
  TitleCasePipe,
  CountryCodePipe,
  RoleCheckPipe,
  RemainingTimePipe,
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  CountryCodeSelectComponent,
  PlacesAutocompleteComponent,
  ProgressCircleComponent,
];

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  PrimengModule,
  DragDropModule,
  LucideAngularModule,
  GoogleMapsModule,
  NgxEchartsModule,
  GalleriaModule
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PIPES,
  ],
  imports: [
    CommonModule,
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...MODULES,
    ...PIPES
  ],
  providers: [
    ...PIPES, DatePipe
  ]
})
export class SharedModule { }
