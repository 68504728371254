import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  encryptData(dataToEncrypt: object, key: string) {
    let secretKey = CryptoJS.enc.Utf8.parse(key);
    let jsonString = JSON.stringify(dataToEncrypt);
    let encrypted = CryptoJS.AES.encrypt(jsonString, secretKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    return encrypted.toString();
  }

  decryptData(cipherText: string, key: string) {
    let secretKey = CryptoJS.enc.Utf8.parse(key);
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return bytes.toString(CryptoJS.enc.Utf8);
  }
 }
