import { createFeature, createReducer, on } from "@ngrx/store";
import { TenderManagementActions } from "./tender.actions";
import { TenderUiDto } from "../../models/tenders/TenderUiDto";
import { TenderSourcingEventWrapperUiDto } from "../../models/tenders/TenderSourcingEventWrapperUiDto";
import { SourcingEventUiDto } from "../../models/tenders/SourcingEventUiDto";

interface State {
  tenderSourcingEventWrapperDto: TenderSourcingEventWrapperUiDto | null;
  sourcingEventUiDto: SourcingEventUiDto | null;
  tenderUiDto: TenderUiDto | null;
}

const initialState: State = {
  tenderSourcingEventWrapperDto: null,
  sourcingEventUiDto: null,
  tenderUiDto: null,
};

export const tenderManagementFeature = createFeature({
  name: 'TenderManagementFeature',
  reducer: createReducer(
    initialState,
    //SourcingEvent-----------------
    on(TenderManagementActions.setCurrentSourcingEventUiDto, (state, { sourcingEventUiDto }) => {
      localStorage.setItem('currentEvent', JSON.stringify(sourcingEventUiDto));
      return {
        ...state,
        sourcingEventUiDto
      }
    }),
    on(TenderManagementActions.getSourcingEventUiDto, (state) => ({
      ...state,
    })),
    //-----------------------

    // Current Tender Wrapper Ui Dto
    on(TenderManagementActions.setCurrentTenderUiDto, (state, { tenderUiDto }) => ({
      ...state,
      tenderUiDto
    })),

    on(TenderManagementActions.getCurrentTenderUiDto, (state) => ({
      ...state,
    })),
    on(TenderManagementActions.clearCurrentTenderUiDto, (state) => ({
      ...state,
      tenderUiDto: null,
    })),

    on(TenderManagementActions.getAllAdminUsers, (state) => ({
      ...state
    })),

    on(TenderManagementActions.setTenderDraftStatus, (state, { draftSubStatus }) => ({
      ...state,
      sourcingEventUiDto: {
        ...state.sourcingEventUiDto,
        stages: {
          ...state.sourcingEventUiDto?.stages, // Preserve existing stages
          [draftSubStatus]: true // Dynamically set the status to true
        }
      }
    }))

  ),
});

export const {
  name,
  reducer,
  selectTenderManagementFeatureState,
  selectTenderSourcingEventWrapperDto,
  selectTenderUiDto,
  selectSourcingEventUiDto
} = tenderManagementFeature