import { createActionGroup, props, emptyProps } from "@ngrx/store";
import { TechnicalDetailsWrapperUiDto } from "../../models/questionnaire/TechnicalDetailsWrapperUiDto";
import { TenderEvaluationWrapperDto } from "../../models/questionnaire/TenderEvaluationWrapperDto";
import { BidderEvaluationDataDto } from "../../models/questionnaire/BidderEvaluationDataDto";
import { BidderTechnicalQuestionUiDto } from "../../models/questionnaire/BidderTechnicalQuestionUiDto";
import { EvaluationTechnicalQuestionUiDto } from "../../models/questionnaire/EvaluationTechnicalQuestionUiDto";

export const QuestionnaireActions = createActionGroup({
  source: 'Questionnaire',
  events: {
    'Set Questionnaire Details Ui Dto': props<{ technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto }>(),
    'Get Questionnaire Details Ui Dto': emptyProps(),
    'Clear Questionnaire Details Ui Dto': emptyProps(),

    'Set Tender Evaluation Wrapper Dto': props<{ tenderEvaluationWrapperDto: TenderEvaluationWrapperDto }>(),
    'Get Tender Evaluation Wrapper Dto': emptyProps(),
    'Clear Tender Evaluation Wrapper Dto': emptyProps(),

    'Set Bidder Evaluation Data': props<{ bidderEvaluationDataDto: BidderEvaluationDataDto }>(),
    'Get Bidder Evaluation Data': emptyProps(),
    'Clear Bidder Evaluation Data': emptyProps(),

    'Update Bidder Technical Question Ui Dto': props<{ bidderTechnicalQuestionDto: BidderTechnicalQuestionUiDto }>(),

    'Update Re Evaluate Tech Question': props<{ reEvaluationTechnicalQuestion: EvaluationTechnicalQuestionUiDto }>(),
    'Clear Re Evaluate Tech Question': emptyProps(),
  }
})