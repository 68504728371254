import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms"
import { ApplicationUtils } from "../utils/ApplicationUtils";
import { distinctUntilChanged, map } from 'rxjs/operators';

export function ColorValidators(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const validColor =  ApplicationUtils.isValidColor(control.value);
      return !validColor ? { invalidColor: { value: control.value } } : null;
    } else {
      return null;
    }
  };

}

// export function differentUrlsValidator(controlNameToCompare: string): ValidatorFn {
  export function differentUrlsValidator(controlNameToCompare: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const controlToCompare = control.root.get(controlNameToCompare);
  
      if (!controlToCompare) {
        return null;
      }
  
      // Normalize URLs by removing trailing slashes and adding a single slash at the end
      const normalizeUrl = (url: string) => url.replace(/\/+$/, '') + '/';
  
      // Listen for changes in the controlToCompare's value
      controlToCompare.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          control.updateValueAndValidity({ emitEvent: false });
        });
  
      // Check if the normalized URLs are the same
      if (control.value && controlToCompare.value && normalizeUrl(control.value) === normalizeUrl(controlToCompare.value)) {
        return { sameUrl: true };
      }
  
      return null;
    };
}