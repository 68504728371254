import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, firstValueFrom, Observable, tap } from 'rxjs';
import { DeploymentConfigurationDto } from '../models/DeploymentConfigurationDto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { ErrorService } from './error.service';
import { UserInviteDto } from '../models/UserInviteDto';
import { ApplicationConstants } from '../utils/ApplicationConstants';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
 
  private _deploymentConfiguration$ = new BehaviorSubject<DeploymentConfigurationDto | null>(null);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,

  ) { }

  get getDeploymentConfiguration$() { return this._deploymentConfiguration$.asObservable() }

  async loadDeploymentConfiguration() {
    try {
      let apiResponseDto = await firstValueFrom(this.getDeploymentConfiguration());
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this._deploymentConfiguration$.next(apiResponseDto.data as DeploymentConfigurationDto)

      }
    } catch (error) {
      console.error(error);
    }
  }

  internalUserInvitation(userInviteDto: UserInviteDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`onboard-internal-users`, userInviteDto).pipe(
      tap(_ => console.log("Invitation sent")),
      catchError(this.errorService.handleError<any>("Error while sending invitation")));
  }

  resentUserInvitation(emailId: string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('emailId', emailId);
    return this.http.get<ServerAPIResponseDto>(`resend-email-verification`, { params }).pipe(
      tap(_ => console.log("Invitation sent")),
      catchError(this.errorService.handleError<any>("Error while sending invitation")));
  }

  getInternalUsers(orgCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`internal-users/internalUser/${orgCode}`).pipe(
      tap(_ => console.log("Get internal users")),
      catchError(this.errorService.handleError<any>("Error while getting internal users")));
  }

  markResourceActiveOrInActive(resourceId: string, active: boolean, actionName: string) {
    let params = new HttpParams().set('resourceId', resourceId).set('active', active).set('actionName', actionName);
    return this.http.post<ServerAPIResponseDto>('markResourceActiveOrInActive', null, { params }).pipe(
      tap(_ => console.log("User active status change" + resourceId)),
      catchError(this.errorService.handleError<any>("Error while changing user active status" + resourceId)))
  }

  getDeploymentConfiguration(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>('deploymentConfiguration').pipe(
      tap(_ => console.log("Get DeploymentConfig")),
      catchError(this.errorService.handleError<any>("Error while getting applicationDeploymentConfig")));
  }

}
