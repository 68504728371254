import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, catchError, finalize, firstValueFrom, Observable, of, Subject, tap } from 'rxjs';
import { ApprovalWorkflowStatusEnum } from 'src/app/shared/enums/ApprovalWorkflowStatusEnum';
import { DraftSubStatus } from 'src/app/shared/enums/TenderDraftSubStatus';
import { AuctionAddressUiDto } from 'src/app/shared/models/auction/AuctionAddressUIDto';
import { AuctionApprovalWrapperUiDto } from 'src/app/shared/models/auction/AuctionApprovalWrapperUiDto';
import { AuctionConfigurationUiDto } from 'src/app/shared/models/auction/AuctionConfigurationUiDto';
import { AuctionCriticalDatesUiDto } from 'src/app/shared/models/auction/AuctionCriticalDatesUiDto';
import { AuctionDocumentUiDto } from 'src/app/shared/models/auction/AuctionDocumentUiDto';
import { AuctionLotUiDto } from 'src/app/shared/models/auction/AuctionLotUiDto';
import { AuctionLotWrapperUiDto } from 'src/app/shared/models/auction/AuctionLotWrapperUiDto';
import { AuctionSubmitDetailsUiDto } from 'src/app/shared/models/auction/AuctionSubmitDetailsUiDto';
import { AuctionSummaryProjection } from 'src/app/shared/models/auction/AuctionSummaryProjection';
import { AuctionUiDto } from 'src/app/shared/models/auction/AuctionUiDto';
import { UserLotEvaluationDataUiDto } from 'src/app/shared/models/auction/UserLotEvaluationDataUiDto';
import { CurrencyDto } from 'src/app/shared/models/CurrencyDto';
import { DocumentArchiveUiDto } from 'src/app/shared/models/DocumentArchiveUiDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { ApprovalWorkflowUiDto } from 'src/app/shared/models/tenders/ApprovalWorkflowUiDto';
import { ApproveManageUiDto } from 'src/app/shared/models/tenders/ApproveManageUiDto';
import { AuctionInitialUIDto } from 'src/app/shared/models/tenders/TenderInitialUiDto';
import { TenderReferenceUiDto } from 'src/app/shared/models/tenders/TenderReferenceDto';
import { TenderRequestDto } from 'src/app/shared/models/tenders/TenderRequestDto';
import { TenderSourcingEventWrapperUiDto } from 'src/app/shared/models/tenders/TenderSourcingEventWrapperUiDto';
import { ErrorService } from 'src/app/shared/services/error.service';
import { FileService } from 'src/app/shared/services/file.service';
import { TenderConfigurationService } from 'src/app/shared/services/tender/tenderConfiguration.service';
import { TenderInitialService } from 'src/app/shared/services/tender/tenderInitial.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { AuctionActions } from 'src/app/shared/state-management/auction/auction.actions';
import { selectSourcingEventUiDto } from 'src/app/shared/state-management/auction/auction.features';
import { selectOrganizationUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { ApplicationConstants } from 'src/app/shared/utils/ApplicationConstants';

@Injectable({
  providedIn: 'root'
})
export class AuctionService {
  actionPerformed = new Subject<{ actionName: string, componentName: DraftSubStatus }>();

  private _tenderDataDTO$ = new BehaviorSubject<TenderSourcingEventWrapperUiDto>({});
  private tenderDataDTO: TenderSourcingEventWrapperUiDto = {};
  get getTenderData$() { return this._tenderDataDTO$.asObservable(); }


  private _tenderRefrenceNoList$ = new BehaviorSubject<TenderReferenceUiDto[]>([]);
  get getTenderRefrenceNoList$() { return this._tenderRefrenceNoList$.asObservable(); }
  private tenderRefrenceNoList: TenderReferenceUiDto[] = [];


  private _auctionConfigurationUiDTO$ = new BehaviorSubject<AuctionConfigurationUiDto>({});
  public get auctionConfigurationUiDTO$() {
    return this._auctionConfigurationUiDTO$;
  }
  private companyCurrency$ = new BehaviorSubject<CurrencyDto[]>([]);
  get getCompanyCurrency$() { return this.companyCurrency$.asObservable(); }
  private auctionConfigUiDto: AuctionConfigurationUiDto = {};

  private _auctionAddressUiDTO$ = new BehaviorSubject<AuctionAddressUiDto>({});
  public get auctionAddressUiDTO$() {
    return this._auctionAddressUiDTO$;
  }
  private auctionAddressConfigUiDto: AuctionAddressUiDto = {};


  private _AuctionCriticalDateUiDto$ = new BehaviorSubject<AuctionCriticalDatesUiDto>({});
  public get auctionCriticalDateUiDto$() {
    return this._AuctionCriticalDateUiDto$;
  }
  private auctionCriticalDatesConfigUiDto: AuctionCriticalDatesUiDto = {};



  private _auctionDocumentUiDTO$ = new BehaviorSubject<AuctionDocumentUiDto>({});
  public get auctionDocumentUiDTO$() {
    return this._auctionDocumentUiDTO$;
  }
  private tenderConfigUiDto: AuctionDocumentUiDto = {};


  private _auctionApprovalWrapperUiDto$ = new BehaviorSubject<AuctionApprovalWrapperUiDto>({});
  public get auctionApprovalWrapperUiDto$() {
    return this._auctionApprovalWrapperUiDto$;
  }
  private approvalWrapperUiDto: AuctionApprovalWrapperUiDto = {};


  private _auctionLotWrapperUiDto$ = new BehaviorSubject<AuctionLotWrapperUiDto>({});
  public get auctionLotWrapperUiDto$() {
    return this._auctionLotWrapperUiDto$;
  }
  private auctionLotWrapperUiDto: AuctionLotWrapperUiDto = {};


  private _auctionSummaryProjection$ = new BehaviorSubject<AuctionSummaryProjection>({});
  public get auctionSummaryProjection$() {
    return this._auctionSummaryProjection$
  }
  private auctionSummaryProjection: AuctionSummaryProjection = {};

  isLoading$ = new BehaviorSubject<boolean>(false);

  private _auctionUiDto$ = new BehaviorSubject<AuctionUiDto>({});
  public get auctionUiDto$() {
    return this._auctionUiDto$;
  }
  private auctionUiDto: AuctionUiDto = {};

  private _auctionEvaluationData$ = new BehaviorSubject<UserLotEvaluationDataUiDto[]>([]);
  public get auctionEvaluationData$() {
    return this._auctionEvaluationData$;
  }
  private auctionEvaluationData: UserLotEvaluationDataUiDto[] = [];

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private themeService: ThemeService,
    private store: Store,
    private tenderInitialService: TenderInitialService,
    private tenderConfigurationService: TenderConfigurationService,
    private fileService: FileService
  ) { }


  // QUESTION - which all Dto will be changed?
  async loadSourcingEventUiDto(auctionRequestDto: TenderRequestDto) {
    try {
      let apiResponseDto = await firstValueFrom(this.getAllSourcingEventList(auctionRequestDto));
      console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setTenderSourcingEventWrapperUiDto(apiResponseDto.data as TenderSourcingEventWrapperUiDto);
      } else {
        this.setTenderSourcingEventWrapperUiDto({} as TenderSourcingEventWrapperUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setTenderSourcingEventWrapperUiDto(tenderData: TenderSourcingEventWrapperUiDto) {
    this.tenderDataDTO = tenderData;
    this._tenderDataDTO$.next(this.tenderDataDTO);
  }


  // INITIATE SECTION

  auctionInitiate(auctionInitialUiDto: AuctionInitialUIDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/auction-initial`, auctionInitialUiDto).pipe(
      tap(_ => console.log("Save Auction")),
      catchError(this.errorService.handleError<any>("Error while saving auction")),
      finalize(() => this.isLoading$.next(false)))
      ;
  }

  getAllSourcingEventList(auctionReqDto: TenderRequestDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/auctions`, auctionReqDto).pipe(
      tap(_ => console.log("Fetch tender list for auctions")),
      catchError(this.errorService.handleError<any>("Error while fetching tender list for auctions")),
      finalize(() => this.isLoading$.next(false))
    );
  }



  // CONFIGURATION SECTION

  async getAuctionConfiguration(tenderId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchTenderConfiguration(tenderId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setConfigurationData(apiResponseDto.data as AuctionConfigurationUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  fetchTenderConfiguration(tenderId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.get<ServerAPIResponseDto>(`auctions/${tenderId}/configuration`).pipe(
      tap(_ => console.log("Fetch tender list")),
      catchError(this.errorService.handleError<any>("Error while fetching tender configdata")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  setConfigurationData(auctionConfData: AuctionConfigurationUiDto) {
    this.auctionConfigUiDto = auctionConfData;
    this._auctionConfigurationUiDTO$.next(this.auctionConfigUiDto);
  }

  saveAuctionConfiguration(auctionConfUiDto: AuctionConfigurationUiDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/configuration`, auctionConfUiDto).pipe(
      tap(_ => console.log("Save auction configuration")),
      catchError(this.errorService.handleError<any>("Error while saving auction")),
      finalize(() => this.isLoading$.next(false))
    );
  }


  async getCompanyCurrency() {
    try {

      let organizationDto = await firstValueFrom(this.store.pipe(select(selectOrganizationUiDto)));
      let sourcingEvent = await firstValueFrom(this.store.pipe(select(selectSourcingEventUiDto)));

      let apiResponseDto = await firstValueFrom(this.tenderConfigurationService.fetchCompanyCurrency(organizationDto?.orgCode!, sourcingEvent?.companyCode!));
      //console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        var companyCurrency = apiResponseDto.data as CurrencyDto[];
        this.companyCurrency$.next(companyCurrency);
      }
    } catch (error) {
      console.error(error);
    }
  }


  // ADDRESS SECTION


  //Get Auction address Data----------------------------
  async getAuctionAddress(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionAddress(auctionId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setAddressData(apiResponseDto.data as AuctionAddressUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }
  setAddressData(auctionAddressData: AuctionAddressUiDto) {
    if (auctionAddressData) {
      this.auctionAddressConfigUiDto = auctionAddressData;
      this._auctionAddressUiDTO$.next(this.auctionAddressConfigUiDto);
    }

  }
  fetchAuctionAddress(auctionId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.get<ServerAPIResponseDto>(`auctions/${auctionId}/address`).pipe(
      tap(_ => console.log("Fetch auction list")),
      catchError(this.errorService.handleError<any>("Error while fetching auction configdata")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  //---------------------------------------------------

  //Save Auction address data--------------------------
  saveAuctionAddress(auctionAddData: AuctionAddressUiDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/address`, auctionAddData).pipe(
      tap(_ => console.log("Save Auction address")),
      catchError(this.errorService.handleError<any>("Error while saving auction address")),
      finalize(() => this.isLoading$.next(false))
    );
  }




  // CRITICAL DATE SECTION

  async getTenderCriticalDate(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionCriticalDate(auctionId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setCriticalDateData(apiResponseDto.data as AuctionCriticalDatesUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setCriticalDateData(auctionCriticalDateData: AuctionCriticalDatesUiDto) {
    if (auctionCriticalDateData) {
      this.auctionCriticalDatesConfigUiDto = auctionCriticalDateData;
      console.log('auctionCriticalDateData', auctionCriticalDateData);
      this._AuctionCriticalDateUiDto$.next(this.auctionCriticalDatesConfigUiDto);
    }
  }

  fetchAuctionCriticalDate(auctionId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.get<ServerAPIResponseDto>(`auctions/${auctionId}/critical-dates`).pipe(
      tap(_ => console.log("Fetch auction critical dates list")),
      catchError(this.errorService.handleError<any>("Error while fetching auction critical dates")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  saveAuctionCriticalDate(auctionCriticalDateData: AuctionCriticalDatesUiDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/critical-dates`, auctionCriticalDateData).pipe(
      tap(_ => console.log("Saved auction CriticalDates")),
      catchError(this.errorService.handleError<any>("Error while saving auction critical dates")),
      finalize(() => this.isLoading$.next(false))
    );
  }




  // DOCUMENT UPLOAD SECTION
  async getAuctionDocument(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionDocument(auctionId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setDocumentData(apiResponseDto.data as AuctionDocumentUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setDocumentData(auctionDocumentData: AuctionDocumentUiDto) {
    if (auctionDocumentData) {
      this.tenderConfigUiDto = auctionDocumentData;
      this._auctionDocumentUiDTO$.next(this.tenderConfigUiDto);
    }
  }

  fetchAuctionDocument(auctionId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.get<ServerAPIResponseDto>(`auctions/${auctionId}/documents`).pipe(
      tap(_ => console.log("Fetch auction document list")),
      catchError(this.errorService.handleError<any>("Error while fetching auction document")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  saveAuctionDocument(auctionDocumentData: DocumentArchiveUiDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/documents`, auctionDocumentData).pipe(
      tap(_ => console.log("Saved auction document")),
      catchError(this.errorService.handleError<any>("Error while saving auction document")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  updateAuctionNextStep(auctionId: string, status: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`auctions/${auctionId}/documents/${status}`, {}).pipe(
      tap(_ => console.log("Saved auction document next step")),
      catchError(this.errorService.handleError<any>("Error while saving auction document next step")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  downloadAuctionDocument(fileId: string) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json',
      'headers': headers
    };
    return this.http.get<any>('downloadBlob?fileId=' + fileId, httpOptions);
  }

  deleteAuctionDocument(auctionId: string, fileId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.delete<ServerAPIResponseDto>(`/auctions/${auctionId}/documents/${fileId}`).pipe(
      tap(_ => console.log("Deleted auction document")),
      catchError(this.errorService.handleError<any>("Error while deleting auction document")),
      finalize(() => this.isLoading$.next(false))
    );
  }





  // Approvals section

  async getAuctionApprovals(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionApprovals(auctionId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setApprovalsData(apiResponseDto.data as AuctionApprovalWrapperUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setApprovalsData(approvalWrapperUiDto: AuctionApprovalWrapperUiDto) {
    if (approvalWrapperUiDto) {
      this.approvalWrapperUiDto = approvalWrapperUiDto;
      this._auctionApprovalWrapperUiDto$.next(this.approvalWrapperUiDto);
    }
  }

  fetchAuctionApprovals(auctionId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.get<ServerAPIResponseDto>(`auctions/${auctionId}/approvals`).pipe(
      tap(_ => console.log("Fetch auction approvals list")),
      catchError(this.errorService.handleError<any>("Error while fetching auction approval data")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  //Update Tender status document to Approval data--------------------------  
  // updateTenderNextStep(tenderId: string, status: string): Observable<ServerAPIResponseDto> {
  //   return this.http.post<ServerAPIResponseDto>(`auctions/${tenderId}/documents/${status}`, {}).pipe(
  //     tap(_ => console.log("Save tender document")),
  //     catchError(this.errorService.handleError<any>("Error while saving tender")));
  // }

  //---------------------------------------------------

  //Save Tender Approvals data--------------------------
  saveAuctionApprovals(auctionApprovalsUiDto: ApprovalWorkflowUiDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/approvals`, auctionApprovalsUiDto).pipe(
      tap(_ => console.log("Save auction Approvals")),
      catchError(this.errorService.handleError<any>("Error while saving auction approvals")),
      finalize(() => this.isLoading$.next(false))
    );
  }




  // LOT SECTION

  async getAuctionLots(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionLots(auctionId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setLotData(apiResponseDto.data as AuctionLotWrapperUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setLotData(auctionLotWrapperUiDto: AuctionLotWrapperUiDto) {
    if (auctionLotWrapperUiDto) {
      this.auctionLotWrapperUiDto = auctionLotWrapperUiDto;
      this._auctionLotWrapperUiDto$.next(this.auctionLotWrapperUiDto);
    }
  }

  fetchAuctionLots(auctionId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.get<ServerAPIResponseDto>(`auctions/${auctionId}/lots`).pipe(
      tap(_ => console.log("Fetch auction lots list")),
      catchError(this.errorService.handleError<any>("Error while fetching auction lots")),
      finalize(() => this.isLoading$.next(false))
    );

  }

  savePartialAuctionLotsData(auctionId: string, partialAuctionData: AuctionLotWrapperUiDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/manage-lot`, partialAuctionData).pipe(
      tap(_ => console.log("Save auction lots initial")),
      catchError(this.errorService.handleError<any>("Error while saving auction lots initial")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  saveCompleteSingleLotData(auctionId: string, auctionLotData: AuctionLotUiDto): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/lots`, auctionLotData).pipe(
      tap(_ => console.log("Save auction lots")),
      catchError(this.errorService.handleError<any>("Error while saving auction lots")),
      finalize(() => this.isLoading$.next(false))
    );

    // let auctionLotWrapperUiDto: ServerAPIResponseDto = {
    //   code: "200",
    //   successMessageCode: "SUCCESS-108",
    //   data: {
    //     auctionLotData: auctionLotData
    //   } as AuctionLotUiDto
    // }

    // return of(auctionLotWrapperUiDto)
  }

  saveImagesForLot(files: Array<any>): Observable<ServerAPIResponseDto> {
    if (files.length > 0) {
      let metaDataList = [];
      let formData = new FormData();
      for (let currfile of files) {
        let metaData = {
          'mimeType': currfile.imageType,
          'version': 0,
          'publicApi': true,
          'dataType': currfile.imageType
        };
        metaDataList.push(JSON.stringify(metaData));
        formData.append("files", currfile.image);
      }
      formData.append("metaDataList", JSON.stringify(metaDataList));
      return this.fileService.uploadMultipleFile(formData);
    } else {
      return of({
        code: ApplicationConstants.SUCCESS_CODE
      } as ServerAPIResponseDto);
    }
  }


  validateAuctionDetails(auctionId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/${auctionId}/auction-submit-validation`, {}).pipe(
      tap(_ => console.log("Validate auction details")),
      catchError(this.errorService.handleError<any>("Error while validating auction details")),
      finalize(() => this.isLoading$.next(false))
    );
  }


  submitAuctionDetailsUiDto(auctionSubmitDetailsUiDto: AuctionSubmitDetailsUiDto) {

    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`/auctions/auction-submit-details`, auctionSubmitDetailsUiDto).pipe(
      tap(_ => console.log("Submit auction details")),
      catchError(this.errorService.handleError<any>("Error while submitting auction details")),
      finalize(() => this.isLoading$.next(false))
    );
  }

  async getAuctionUiDtoFromBE(auctionId: string) {
    try {

      let auctionUiDtoResp: ServerAPIResponseDto = await firstValueFrom(this.fetchAuctionUiDto(auctionId));
      if (auctionUiDtoResp && auctionUiDtoResp.code == ApplicationConstants.SUCCESS_CODE) {
        this.setAuctionUiDto(auctionUiDtoResp.data as AuctionUiDto);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setAuctionUiDto(auctionUiDto: AuctionUiDto) {
    if (auctionUiDto) {
      this.store.dispatch(AuctionActions.setCurrentAuctionUiDto({ auctionUiDto: auctionUiDto }));
      this.auctionUiDto = auctionUiDto;
      this._auctionUiDto$.next(this.auctionUiDto);
    }
  }

  async loadAuctionUiDtoByAuctionId(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionUiDto(auctionId!));

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let auctionUiDto = apiResponseDto.data as AuctionUiDto;
        this.store.dispatch(AuctionActions.setCurrentAuctionUiDto({ auctionUiDto }));
      } else {
        console.error(apiResponseDto.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  fetchAuctionUiDto(auctionId: string): Observable<ServerAPIResponseDto> {
    this.isLoading$.next(true);
    return this.http.get<ServerAPIResponseDto>(`auctions/${auctionId}`).pipe(
      tap(_ => console.log("Fetch auction data")),
      catchError(this.errorService.handleError<any>("Error while fetching auction data")),
      finalize(() => this.isLoading$.next(false))
    );
  }


  /// APPROVAL AND REJECTION

  manageAuctionApproval(approveManageUiDto: ApproveManageUiDto) {
    return this.http.post<ServerAPIResponseDto>(`/auctions/manage-approval`, approveManageUiDto).pipe(
      tap(_ => console.log("Update approval of auction " + approveManageUiDto.tenderId)),
      catchError(this.errorService.handleError<any>("Error while updating auction")));
  }

  fetchLotEvaluationData(auctionId: string, lotId: string) {
    let params = new HttpParams().set('auctionId', auctionId).set('lotId', lotId);
    return this.http.get<ServerAPIResponseDto>(`auction-evaluation/auction-evaluation-lot-data`, { params }).pipe(
      tap(_ => console.log("Fetch auction data")),
      catchError(this.errorService.handleError<any>("Error while fetching auction data")));
  }

  /// AUCTION SUMMARY

  fetchAuctionSummaryProjection(auctionId: string) {
    this.isLoading$.next(true);
    return this.http.post<ServerAPIResponseDto>(`auction-evaluation/summary`, null, { params: new HttpParams().set('auctionId', auctionId) }).pipe(
      tap(_ => console.log("Fetch auction summary projection")),
      catchError(this.errorService.handleError<any>("Error while fetching auction summary projection")),
      finalize(() => this.isLoading$.next(false)));
  }

  async loadAuctionSummaryProjection(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionSummaryProjection(auctionId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setAuctionSummaryProjection(apiResponseDto.data as AuctionSummaryProjection);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setAuctionSummaryProjection(auctionSummaryProjection: AuctionSummaryProjection) {
    if (auctionSummaryProjection) {
      this.auctionSummaryProjection = auctionSummaryProjection;
      this._auctionSummaryProjection$.next(this.auctionSummaryProjection);
    }
  }



  async loadAuctionEvaluationData(auctionId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchAuctionEvaluationData(auctionId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setAuctionEvaluationData(apiResponseDto.data as UserLotEvaluationDataUiDto[]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  setAuctionEvaluationData(auctionEvaluationData: UserLotEvaluationDataUiDto[]) {
    if (auctionEvaluationData) {
      this.auctionEvaluationData = auctionEvaluationData;
      this._auctionEvaluationData$.next(this.auctionEvaluationData);
    }
  }

  fetchAuctionEvaluationData(auctionId: string) {
    return this.http.get<ServerAPIResponseDto>(`auction-evaluation/auction-evaluation-data`, { params: new HttpParams().set('auctionId', auctionId) }).pipe(
      tap(_ => console.log("Fetch auction evaluation data")),
      catchError(this.errorService.handleError<any>("Error while fetching auction evaluation data")));
  }


  rejectedDraftToDraft(auctionId: string) {
    return this.http.post<ServerAPIResponseDto>(`auctions/${auctionId}/markAuctionDraft`, {}).pipe(
      tap(_ => console.log("Auction Updated successfully")),
      catchError(this.errorService.handleError<any>("Error while updating the auction")));
  }

}
