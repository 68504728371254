import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from '../error.service';
import { BehaviorSubject, catchError, firstValueFrom, Observable, tap } from 'rxjs';

import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { TenderConfigurationUiDto } from '../../models/tenders/TenderConfigurationUiDto';
import { CurrencyDto } from '../../models/CurrencyDto';
import { selectSourcingEventUiDto } from '../../state-management/tender/tender.features';
import { TenderTargetBidderUiDto } from '../../models/TenderTargetBidderUiDto';
import { selectOrganizationUiDto } from '../../state-management/onboarding/onboarding.features';


@Injectable({
  providedIn: 'root'
})
export class TenderConfigurationService {
  private _tenderConfigurationUiDTO$ = new BehaviorSubject<TenderConfigurationUiDto>({});
  private tenderConfigUiDto: TenderConfigurationUiDto = {};

  private companyCurrency$ = new BehaviorSubject<CurrencyDto[]>([]);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService,
    private store: Store
  ) { }

  get getTenderConfigurationData$() { return this._tenderConfigurationUiDTO$.asObservable(); }

  get getCompanyCurrency$() { return this.companyCurrency$.asObservable(); }

  //Get Tender configuration Data----------------------------
  async getTenderConfiguration(tenderId: string) {
    try {
      let apiResponseDto = await firstValueFrom(this.fetchTenderConfiguration(tenderId));
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.setConfigurationData(apiResponseDto.data as TenderConfigurationUiDto)
      }
    } catch (error) {
      console.error(error);
    }
  }

  setConfigurationData(tenderConfigurationData: TenderConfigurationUiDto) {
    this.tenderConfigUiDto = tenderConfigurationData;
    this._tenderConfigurationUiDTO$.next(this.tenderConfigUiDto);
  }
  fetchTenderConfiguration(tenderId: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`tenders/${tenderId}/configuration`).pipe(
      tap(_ => console.log("Fetch tender list")),
      catchError(this.errorService.handleError<any>("Error while fetching tender configdata")));
  }

  //---------------------------------------------------

  //Save Tender configuration data--------------------------
  saveTenderConfiguration(tenderConfigurationUiDto: TenderConfigurationUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`/tenders/configuration`, tenderConfigurationUiDto).pipe(
      tap(_ => console.log("Save tender configuration")),
      catchError(this.errorService.handleError<any>("Error while saving tender")));
  }

  //Get Tender CriticalDate Data----------------------------
  async getCompanyCurrency() {
    try {

      let organizationDto = await firstValueFrom(this.store.pipe(select(selectOrganizationUiDto)));
      let sourcingEvent = await firstValueFrom(this.store.pipe(select(selectSourcingEventUiDto)));

      let apiResponseDto = await firstValueFrom(this.fetchCompanyCurrency(organizationDto?.orgCode!, sourcingEvent?.companyCode!));
      //console.log('apiResponseDtoEvent', apiResponseDto)
      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        var companyCurrency = apiResponseDto.data as CurrencyDto[];
        this.companyCurrency$.next(companyCurrency);
      }
    } catch (error) {
      console.error(error);
    }
  }

  fetchCompanyCurrency(orgCode: string, companyCode: string): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`companies/${orgCode}/${companyCode}/currencies`).pipe(
      tap(_ => console.log("Fetch currency list")),
      catchError(this.errorService.handleError<any>("Error while fetching company currency")));
  }

  fetchBidderList(): Observable<ServerAPIResponseDto> {
    return this.http.get<ServerAPIResponseDto>(`bidders`).pipe(
      tap(_ => console.log("Fetch bidder list")),
      catchError(this.errorService.handleError<any>("Error while fetching bidder list")));
  }

  saveTenderTargetedBidders(uiDto:TenderTargetBidderUiDto): Observable<ServerAPIResponseDto> {
    return this.http.post<ServerAPIResponseDto>(`tenders/targetBidders`, uiDto).pipe(
      tap(_ => console.log("Saved bidder user successfully")),
      catchError(this.errorService.handleError<any>("Error while saving bidder user")));
  }
  

}
