import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationUtils } from '../utils/ApplicationUtils';
import { TimeZoneDto } from '../models/TimeZoneDto';

@Pipe({
  name: 'remainingTime',
  pure: false
})
export class RemainingTimePipe implements PipeTransform {
  transform(targetDate: string | Date): string {

    let target = ApplicationUtils.convertDateFormat(targetDate.toString(), TimeZoneDto.default())!;

    const currentDate = ApplicationUtils.getCurrentDate(TimeZoneDto.default());
    
    // Ensure the target date is in the future
    if (target <= currentDate) {
      return 'Time is up'; // If the target date is in the past or current, return a message
    }

    const diffInMs = target.getTime() - currentDate.getTime(); // Get the difference in milliseconds
    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    // Calculate remaining time in days, hours, and minutes
    const remainingDays = diffInDays;
    const remainingHours = diffInHours % 24;
    const remainingMinutes = diffInMinutes % 60;

    let remainingTime = '';
    if (remainingDays > 0) {
      remainingTime += `${remainingDays} days `;
    }
    if (remainingHours > 0) {
      remainingTime += `${remainingHours} hours `;
    }
    if (remainingMinutes > 0) {
      remainingTime += `${remainingMinutes} minutes`;
    }

    return remainingTime || 'Less than a minute';
  }
}
