<div id="main" [ngClass]="{'bg-slate-100' : isLoggedIn}">
  <app-header *ngIf="isShowHeader && isLoggedIn"></app-header>
  <router-outlet></router-outlet>
</div>

<!-- <p-toast [baseZIndex]="9999999999999"></p-toast> -->


<p-toast position="top-right" styleClass="w-80 block" [baseZIndex]="9999999999999">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-col  p-2 bg-white" style="flex: 1"
      [ngClass]="{
        'border-s-4 border-emerald-600' : message.severity == 'success',
        'border-s-4 border-sky-600' : message.severity == 'info',
        'border-s-4 border-rose-600' : message.severity == 'error'
      }">
      <div class="flex flex-row items-center">
        <div class="flex me-2 ">
          <lucide-icon name="circle-check" [size]="32" class="my-icon text-emerald-600"
            *ngIf="message.severity == 'success'"></lucide-icon>
          <lucide-icon name="circle-x" [size]="32" class="my-icon text-rose-600"
            *ngIf="message.severity == 'error'"></lucide-icon>
        </div>
        <div class="flex flex-col">
          <div class="text-sm font-semibold text-gray-800">{{message.summary}}</div>
          <p class="text-sm font-medium text-gray-700">{{message.detail}}</p>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>