import { createFeature, createReducer, on } from "@ngrx/store";
import { TechnicalDetailsWrapperUiDto } from "../../models/questionnaire/TechnicalDetailsWrapperUiDto";
import { QuestionnaireActions } from "./questionnaire.actions";
import { TenderEvaluationWrapperDto } from "../../models/questionnaire/TenderEvaluationWrapperDto";
import { BidderEvaluationDataDto } from "../../models/questionnaire/BidderEvaluationDataDto";
import { EvaluationTechnicalQuestionUiDto } from "../../models/questionnaire/EvaluationTechnicalQuestionUiDto";

interface State {
  technicalDetailsWrapperUiDto: TechnicalDetailsWrapperUiDto | null
  tenderEvaluationWrapperDto: TenderEvaluationWrapperDto | null
  bidderEvaluationDataDto: BidderEvaluationDataDto | null
  reEvaluationTechnicalQuestion: EvaluationTechnicalQuestionUiDto | null
}

const initialState: State = {
  technicalDetailsWrapperUiDto: null,
  tenderEvaluationWrapperDto: null,
  bidderEvaluationDataDto: null,
  reEvaluationTechnicalQuestion: null
};


export const questionnaireFeature = createFeature({
  name: 'QuestionnaireFeature',
  reducer: createReducer(
    initialState,

    on(QuestionnaireActions.setQuestionnaireDetailsUiDto, (state, { technicalDetailsWrapperUiDto }) => ({
      ...state,
      technicalDetailsWrapperUiDto
    })),
    on(QuestionnaireActions.getQuestionnaireDetailsUiDto, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearQuestionnaireDetailsUiDto, (state) => ({
      ...state,
      technicalDetailsWrapperUiDto: null,
    })),

    on(QuestionnaireActions.setTenderEvaluationWrapperDto, (state, { tenderEvaluationWrapperDto }) => ({
      ...state,
      tenderEvaluationWrapperDto
    })),
    on(QuestionnaireActions.getTenderEvaluationWrapperDto, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearTenderEvaluationWrapperDto, (state) => ({
      ...state,
      tenderEvaluationWrapperDto: null,
    })),

    on(QuestionnaireActions.setBidderEvaluationData, (state, { bidderEvaluationDataDto }) => {
      // Find the index of the bidder summary data to update
      const updatedBidderEvaluationSummaryDataDtoList = state.tenderEvaluationWrapperDto?.bidderEvaluationSummaryDataDtoList?.map((summary) => {
        if (summary.bidderUserId === bidderEvaluationDataDto.bidderUserId) {
          // Update the evaluationStatus field
          return {
            ...summary,
            evaluationStatus: bidderEvaluationDataDto.evaluationStatus,
          };
        }
        return summary; // Keep other summaries unchanged
      });
    
      return {
        ...state,
        bidderEvaluationDataDto, // Update bidderEvaluationDataDto
        tenderEvaluationWrapperDto: {
          ...state.tenderEvaluationWrapperDto,
          bidderEvaluationSummaryDataDtoList: updatedBidderEvaluationSummaryDataDtoList || state.tenderEvaluationWrapperDto?.bidderEvaluationSummaryDataDtoList,
        },
      };
    }),    
    on(QuestionnaireActions.getBidderEvaluationData, (state) => ({
      ...state,
    })),
    on(QuestionnaireActions.clearBidderEvaluationData, (state) => ({
      ...state,
      bidderEvaluationDataDto: null,
    })),

    on(QuestionnaireActions.updateBidderTechnicalQuestionUiDto, (state, { bidderTechnicalQuestionDto }) => ({
      ...state,
      bidderEvaluationDataDto: {
        ...state.bidderEvaluationDataDto,
        evaluationTechnicalQuestionUiDtos: updateOrAddEvaluationTechnicalQuestion(
          state.bidderEvaluationDataDto?.evaluationTechnicalQuestionUiDtos,
          bidderTechnicalQuestionDto
        ),
      },
    })),

    on(QuestionnaireActions.updateReEvaluateTechQuestion, (state, { reEvaluationTechnicalQuestion }) => ({
      ...state,
      reEvaluationTechnicalQuestion
    })),
    on(QuestionnaireActions.clearReEvaluateTechQuestion, (state) => ({
      ...state,
      reEvaluationTechnicalQuestion: null
    }))
  ),
});

const updateOrAddEvaluationTechnicalQuestion = (
  currentArray: EvaluationTechnicalQuestionUiDto[] | undefined,
  newDto: EvaluationTechnicalQuestionUiDto
): EvaluationTechnicalQuestionUiDto[] => {
  const array = currentArray || [];
  const index = array.findIndex(dto => dto.questionId === newDto.questionId);

  if (index > -1) {
    // Update existing DTO
    return [
      ...array.slice(0, index),
      { ...array[index], ...newDto }, // Merge changes
      ...array.slice(index + 1),
    ];
  } else {
    // Add new DTO
    return [...array, newDto];
  }
};

export const {
  name,
  reducer,
  selectTechnicalDetailsWrapperUiDto,
  selectTenderEvaluationWrapperDto,
  selectBidderEvaluationDataDto,
  selectReEvaluationTechnicalQuestion
} = questionnaireFeature