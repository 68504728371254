import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LucideAngularModule, icons } from 'lucide-angular';
import { ConfirmationService, MessageService } from 'primeng/api';
import { navigationFeature } from './shared/state-management/navigation/navigation.features';
import { sessionFeature } from './shared/state-management/session/session.features';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BasicAuthHttpInterceptor } from './shared/interceptors/basic-auth-http.interceptor';
import { SharedModule } from './shared/shared.module';
import { onboardingFeature } from './shared/state-management/onboarding/onboarding.features';
import { NgxEchartsModule } from 'ngx-echarts';
import { tenderManagementFeature } from './shared/state-management/tender/tender.features';
import { questionnaireFeature } from './shared/state-management/questionnaire/questionnaire.features';
import { EncryptionInterceptor } from './shared/interceptors/encryption.interceptor';
import { auctionFeature } from './shared/state-management/auction/auction.features';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { AcAuctionLotAddItemsComponent } from './layouts/Auction/Auction-create/ac-auction-lot-add-items/ac-auction-lot-add-items.component';


@NgModule({
  declarations: [
    AppComponent  
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    LucideAngularModule.pick(icons),
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature(sessionFeature),
    StoreModule.forFeature(onboardingFeature),
    StoreModule.forFeature(navigationFeature),
    StoreModule.forFeature(tenderManagementFeature),
    StoreModule.forFeature(questionnaireFeature),
    StoreModule.forFeature(auctionFeature),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    MessageService, DialogService, ConfirmationService, DynamicDialogConfig,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EncryptionInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
