import { Pipe, PipeTransform } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectUserUiDto } from '../state-management/session/session.features';

@Pipe({
  name: 'roleCheck',
  pure: true
})
export class RoleCheckPipe implements PipeTransform {
  // Role mapping based on role ID
  private roleMap: { [key: string]: number } = {
    'SUPER_ADMIN': 1,
    'ADMINISTRATOR': 2,
    'IT_SETUP': 3,
  };

  constructor(private store: Store) { }

  transform(role: number | string): Observable<boolean> {
    let roleId = typeof (role) == 'number' ? role : this.roleMap[role];
    return this.store.pipe(select(selectUserUiDto), map(user => user?.appAdminCode === roleId));
  }
}